<template>
  <div>
    <YandexMap
      v-if="onlyVenuesWithCoords.length"
      v-model="map"
      width="100%"
      :height="mapHeight"
      :settings="mapSettings"
    >
      <YandexMapDefaultSchemeLayer />
      <YandexMapDefaultFeaturesLayer />
      <YandexMapMarker
        v-for="marker in onlyVenuesWithCoords"
        :key="marker.id"
        :marker-id="marker.id"
        :settings="getMarkerSettings(marker)"
        class="cursor-pointer"
        :class="{ '!cursor-default': disableMarkers }"
        @click="!disableMarkers && onMarkerClick(marker)"
      >
        <div class="flex size-7 rounded-full bg-primary shadow-primary">
          <div class="m-auto size-5 rounded-full bg-main" />
        </div>

        <div
          v-if="activeVenue?.id === marker.id"
          class="z-10 w-screen max-w-[50vw] rounded-xl border border-secondary bg-primary p-3 shadow-poster_primary lg:max-w-80"
        >
          <p class="mb-1 font-bold">
            {{ activeVenue.title }}
          </p>
          <p v-if="activeVenue.address" class="mb-1.5 text-sm text-text-secondary">
            {{ activeVenue.address.normalized }}
          </p>
          <UiButton
            tag="RouterLink"
            :to="{ name: 'VenueView', params: { id: activeVenue.id } }"
            text
            link
          >
            <template v-if="isLgAndUp"> Перейти на площадку мероприятия </template>
            <template v-else> перейти на площадку </template>
          </UiButton>
        </div>
      </YandexMapMarker>
      <YandexMapControls
        v-if="isLgAndUp"
        :settings="{ position: 'top right', orientation: 'vertical' }"
      >
        <YandexMapZoomControl />
      </YandexMapControls>
    </YandexMap>
  </div>
</template>

<script lang="ts" setup>
import type { YMap, LngLatBounds, LngLat } from '@yandex/ymaps3-types'
import type { Nullable } from 'ts-helpers'
import { computed, reactive, shallowRef, watch } from 'vue'
import {
  YandexMap,
  YandexMapControls,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapMarker,
  YandexMapZoomControl,
  getLocationFromBounds
} from 'vue-yandex-maps'
import { useBreakpoint } from '@/6_shared/lib'
import { UiButton } from '@/6_shared/ui'
import type { Venue } from '../../model'

type PropType = {
  value: Venue[]
  activeVenue: Nullable<Venue>
  height?: string
  disableMarkers?: boolean
}
type EmitType = {
  (e: 'update:activeVenue', venue: Nullable<Venue>): void
}

const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()
const {
  lg: [isLgAndUp, isLgAndDown]
} = useBreakpoint()
const onlyVenuesWithCoords = computed(() => props.value.filter((venue) => !!venue.coords))
const mapHeight = computed(() => {
  if (!props.height) return isLgAndUp.value ? '496px' : '340px'

  return props.height
})

const DEFAULT_ZOOM = 14
const map = shallowRef<Nullable<YMap>>(null)

const mapSettings = reactive({
  location: {
    center: [
      onlyVenuesWithCoords.value[0].coords!.longitude,
      onlyVenuesWithCoords.value[0].coords!.latitude
    ],
    zoom: DEFAULT_ZOOM
  }
})

/**
 * данные для маркера
 * @param marker
 */
const getMarkerSettings = (marker: Venue) => {
  return {
    coordinates: [marker.coords!.longitude, marker.coords!.latitude] as LngLat,
    zIndex: props.activeVenue?.id === marker.id ? 1 : 0
  }
}
/**
 * обработка клика по маркеру
 * @param marker
 */
const onMarkerClick = (marker: Venue) => {
  if (props.activeVenue?.id === marker.id) emit('update:activeVenue', null)
  else emit('update:activeVenue', marker)
}

watch(
  () => props.activeVenue,
  (val) => {
    if (!val) return

    const gapX = isLgAndDown.value ? 0.006 : 0
    const gapY = isLgAndDown.value ? -0.004 : 0

    val.coords &&
      map.value?.setLocation({
        center: [val.coords.longitude + gapX, val.coords.latitude + gapY]
      })
  }
)

/**
 * возвращает границы участка
 * по минимальным и максимальным координатам из всех плоащадок
 */
const getBoundsFromCords = (): LngLatBounds => {
  const min = (values: number[]) => values.reduce((x, y) => Math.min(x, y))
  const max = (values: number[]) => values.reduce((x, y) => Math.max(x, y))

  const lngCoords: number[] = []
  const latCoords: number[] = []

  onlyVenuesWithCoords.value.forEach((item) => {
    lngCoords.push(item.coords!.longitude)
    latCoords.push(item.coords!.latitude)
  })

  const minLng = min(lngCoords)
  const minLat = min(latCoords)
  const maxLng = max(lngCoords)
  const maxLat = max(latCoords)

  return [
    [minLng, minLat],
    [maxLng, maxLat]
  ]
}

/**
 * устанавливает центр карты и зум в зависимости от всех координат площадок
 * @param map
 */
const setMapLocationByVenuesCoords = async (map: YMap) => {
  if (props.value.length <= 1) return

  const bounds = getBoundsFromCords()

  let { center, zoom } = await getLocationFromBounds({
    bounds,
    map,
    roundZoom: true,
    comfortZoomLevel: true
  })

  map?.setLocation({
    center,
    zoom: zoom > DEFAULT_ZOOM ? DEFAULT_ZOOM : zoom
  })
}

watch(
  () => map.value,
  (val) => {
    if (val) {
      setTimeout(() => {
        setMapLocationByVenuesCoords(val)
      })
    }
  }
)
</script>
